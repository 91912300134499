/* eslint-disable */
import { pointer } from "../constants";
import _ from "lodash-es";
import fastdom from "../fastdomMb";
import { TinyColor } from "@ctrl/tinycolor";
import { createPointCB, getClientRect } from "dom-plane";
const hueOffset = 15;
const huePoint = {};
const huePointCB = createPointCB(huePoint);
const spectrumPoint = {};
const spectrumPointCB = createPointCB(spectrumPoint);
let HueClientRect;
let PickerClientRect;
let pickerIndicatorOffsetHeight;
let pickerIndicatorOffsetWidth;
let slideIndicatorOffsetHeight;
let theMousePositionBounds;

let eventListeners = {};

// This HTML snippet is inserted into the innerHTML property of the passed color picker element
// when the no-hassle call to MBColorPicker() is used, i.e. MBColorPicker(function(hex, hsv, rgb) { ... });
const template = {
  spectrum: {
    canvas: "touch-action-none color-spectrum-canvas",
    control: "color-spectrum-marker",
  },
  hue: {
    canvas: "touch-action-none color-hue-canvas",
    control: "color-hue-marker",
  },
};

const colorPickerHtml = `
	<div class="color-picker d-flex flex-row">
		<div class="color-picker-wrapper color-spectrum-wrapper position-relative">
			<div class="${template.spectrum.canvas}">
			  <button type="button" class="btn p-0 color-picker-marker ${template.spectrum.control}"></button>
			</div>
		</div>
		<div class="color-picker-wrapper color-hue-wrapper position-relative ml-3">
			<div class="${template.hue.canvas}">
			  <button type="button" class="btn rounded-0 p-0 color-picker-marker ${template.hue.control}"></button>
      </div>
		</div>
	</div>
`;

/**
 * Return mouse position relative to the element el, optionally
 * clipping the coordinates to be inside the element
 */
function mousePosition(event, theElement, isHue) {
  let result;
  let point;
  if (isHue) {
    huePointCB(event);
    point = huePoint;
    if (_.isUndefined(HueClientRect)) {
      theMousePositionBounds = getClientRect(theElement);
    }
  } else {
    spectrumPointCB(event);
    point = spectrumPoint;
    if (_.isUndefined(PickerClientRect)) {
      theMousePositionBounds = getClientRect(theElement);
    }
  }

  result = {
    x: point.clientX - theMousePositionBounds.left,
    y: point.clientY - theMousePositionBounds.top,
  };

  result.x = Math.max(0, Math.min(theElement.offsetWidth, result.x));
  result.y = Math.max(0, Math.min(theElement.offsetHeight, result.y));

  return result;
}

/**
 * Convert HSV representation to RGB HEX string.
 * Credits to http://www.raphaeljs.com
 */
function hsv2rgb(hsv) {
  const color = new TinyColor(hsv);

  const states = {
    isValid: color.isValid,
    isLight: color.isLight(),
    isDark: color.isDark(),
  };

  return _.merge(color.toRgb(), { hex: color.toHexString() }, { states });
}

/**
 * Return click event handler for the slider.
 * Sets picker background color and calls ctx.callback if provided.
 */
function slideListener(ctx, slideElement, pickerElement) {
  return (event) => {
    fastdom.measure(() => {
      const mouse = mousePosition(event, slideElement, true);
      ctx.h = (mouse.y / slideElement.offsetHeight) * 360 + hueOffset;
      const pickerColor = hsv2rgb({ h: ctx.h, s: 1, v: 1 });
      const c = hsv2rgb({ h: ctx.h, s: ctx.s, v: ctx.v });
      fastdom.mutate(() => {
        pickerElement.style.backgroundColor = pickerColor.hex;
        ctx.callback &&
          ctx.callback(
            c.hex,
            { h: ctx.h - hueOffset, s: ctx.s, v: ctx.v },
            { r: c.r, g: c.g, b: c.b },
            c.states,
            undefined,
            mouse
          );
      });
    });
  };
}

/**
 * Return click event handler for the picker.
 * Calls ctx.callback if provided.
 */
function pickerListener(ctx, pickerElement) {
  return (event) => {
    fastdom.measure(() => {
      const mouse = mousePosition(event, pickerElement, false);
      const width = pickerElement.offsetWidth;
      const height = pickerElement.offsetHeight;
      ctx.s = mouse.x / width;
      ctx.v = (height - mouse.y) / height;
      const c = hsv2rgb(ctx);
      fastdom.mutate(() => {
        ctx.callback &&
          ctx.callback(
            c.hex,
            { h: ctx.h - hueOffset, s: ctx.s, v: ctx.v },
            { r: c.r, g: c.g, b: c.b },
            c.states,
            mouse,
            undefined
          );
      });
    });
  };
}

function addEventListener(element, event, listener) {
  if (!eventListeners[element.className || element.id]) {
    eventListeners[element.className || element.id] = {};
  }
  if (!eventListeners[element.className || element.id][event]) {
    eventListeners[element.className || element.id][event] = [];
  }
  eventListeners[element.className || element.id][event] = [element, listener];
  element.addEventListener(event, listener, false);
}

function removeEventListener(element, event, listener) {
  element.removeEventListener(event, listener, false);
}

/**
 * Enable drag&drop color selection.
 * @param {object} ctx MBColorPicker instance.
 * @param {DOMElement} element HSV slide element or HSV picker element.
 * @param {Function} listener Function that will be called whenever mouse is dragged over the element with event object as argument.
 */
function enableDragging(ctx, element, listener) {
  let mouseDown = false;
  addEventListener(element, "pointerdown", () => (mouseDown = true));
  addEventListener(element, "pointerup", () => (mouseDown = false));
  addEventListener(element, "pointerout", (event) => {
    if (element.contains(event.relatedTarget)) {
      return;
    }
    mouseDown = false;
  });

  addEventListener(element, "pointermove", (event) => {
    if (mouseDown) {
      listener(event);
    }
  });
}

/**
 * Sets color of the picker in hsv/rgb/hex format.
 * @param {object} ctx MBColorPicker instance.
 * @param {string} theColor
 */
function setColor(ctx, theColor) {
  const color = new TinyColor(theColor);
  const hex = color.toHexString();
  const hsv = color.toHsv();
  const rgb = color.toRgb();

  ctx.h = hsv.h % 360;
  ctx.s = hsv.s;
  ctx.v = hsv.v;
  const c = hsv2rgb(ctx);

  fastdom.measure(() => {
    const mouseSlide = {
      y: (ctx.h * ctx.slideElement.offsetHeight) / 360,
      x: 0, // not important
    };
    const pickerHeight = ctx.pickerElement.offsetHeight;
    const mousePicker = {
      x: ctx.s * ctx.pickerElement.offsetWidth,
      y: pickerHeight - ctx.v * pickerHeight,
    };
    fastdom.mutate(() => {
      ctx.pickerElement.style.backgroundColor = hsv2rgb({ h: ctx.h, s: 1, v: 1 }).hex;
      ctx.callback &&
        ctx.callback(
          hex || c.hex,
          { h: ctx.h, s: ctx.s, v: ctx.v },
          rgb || { r: c.r, g: c.g, b: c.b },
          c.states,
          mousePicker,
          mouseSlide
        );
      return ctx;
    });
  });
}

/**
 *
 * @param element
 * @param transformValue
 */
function applyCssTransform(element, transformValue) {
  // It's important to trim the result, because the browser will ignore the set operation
  // if the string contains only whitespace.
  let value = transformValue.trim();
  element.style.transform = value;
  element.style.webkitTransform = value;
}

/**
 * MBColorPicker.
 * @param {DOMElement} slideElement HSV slide element.
 * @param {DOMElement} pickerElement HSV picker element.
 * @param {Function} callback Called whenever the color is changed provided chosen color in RGB HEX format as the only argument.
 */
export class MBColorPicker {
  constructor(slideElement, pickerElement, callback) {
    if (!(this instanceof MBColorPicker)) {
      return new MBColorPicker(slideElement, pickerElement, callback);
    }

    this.h = 0;
    this.s = 1;
    this.v = 1;

    if (!callback) {
      // call of the form MBColorPicker(element, funtion(hex, hsv, rgb) { ... }), i.e. the no-hassle call.

      const element = slideElement;
      element.innerHTML = colorPickerHtml;

      this.slideElement = element.getElementsByClassName(template.hue.canvas)[0];
      this.pickerElement = element.getElementsByClassName(template.spectrum.canvas)[0];
      this.slideIndicator = element.getElementsByClassName(template.hue.control)[0];
      this.pickerIndicator = element.getElementsByClassName(template.spectrum.control)[0];

      this.callback = (hex, hsv, rgb, isValid, pickerCoordinate, slideCoordinate) => {
        MBColorPicker.positionIndicators(this.slideIndicator, this.pickerIndicator, slideCoordinate, pickerCoordinate);
        pickerElement(hex, hsv, rgb, isValid);
      };
    } else {
      this.callback = callback;
      this.pickerElement = pickerElement;
      this.slideElement = slideElement;
    }

    this.addHandlers();
  }

  setColor(theColor) {
    return setColor(this, theColor);
  }

  addHandlers() {
    addEventListener(this.slideElement, "click", slideListener(this, this.slideElement, this.pickerElement));
    addEventListener(this.pickerElement, "click", pickerListener(this, this.pickerElement));
    enableDragging(this, this.slideElement, slideListener(this, this.slideElement, this.pickerElement));
    enableDragging(this, this.pickerElement, pickerListener(this, this.pickerElement));
  }

  removeHandlers() {
    const nodeKeys = _.keys(eventListeners);
    nodeKeys.forEach((nodeKey) => {
      const eventKeys = _.keys(eventListeners[nodeKey]);
      eventKeys.forEach((eventKey) => {
        removeEventListener(eventListeners[nodeKey][eventKey][0], eventKey, eventListeners[nodeKey][eventKey][1]);
        delete eventListeners[nodeKey][eventKey];
      });
    });
  }

  /**
   * Helper to position indicators.
   * @param {HTMLElement} slideIndicator DOM element representing the indicator of the slide area.
   * @param {HTMLElement} pickerIndicator DOM element representing the indicator of the picker area.
   * @param {object} mouseSlide Coordinates of the mouse cursor in the slide area.
   * @param {object} mousePicker Coordinates of the mouse cursor in the picker area.
   */
  static positionIndicators(slideIndicator, pickerIndicator, mouseSlide, mousePicker) {
    if (mouseSlide) {
      fastdom.measure(() => {
        if (_.isUndefined(slideIndicatorOffsetHeight)) {
          slideIndicatorOffsetHeight = slideIndicator.offsetHeight / 2;
        }
        const top = mouseSlide.y - slideIndicatorOffsetHeight;
        fastdom.mutate(() => {
          applyCssTransform(slideIndicator, `translateY(${top}px)`);
        });
      });
    }
    if (mousePicker) {
      fastdom.measure(() => {
        if (_.isUndefined(pickerIndicatorOffsetHeight) || _.isUndefined(pickerIndicatorOffsetWidth)) {
          pickerIndicatorOffsetHeight = pickerIndicator.offsetHeight / 2;
          pickerIndicatorOffsetWidth = pickerIndicator.offsetWidth / 2;
        }

        const top = mousePicker.y - pickerIndicatorOffsetHeight;
        const left = mousePicker.x - pickerIndicatorOffsetWidth;
        fastdom.mutate(() => {
          applyCssTransform(pickerIndicator, `translate(${left}px,${top}px)`);
        });
      });
    }
  }
}
