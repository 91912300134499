/* eslint-disable */
import Cropper from "cropperjs";
import Flow from "@flowjs/flow.js";
import Pace from "./pace";
import anime from "animejs";
import autosize from "autosize";
import elementReady from "./ready";
import fastdom from "./fastdomMb";
import sha256 from "js-sha256";
import tabbable from "tabbable";
import tippy from "tippy.js";
import { MBColorPicker } from "./mb-colorpicker";
import { MBUI } from "./constants";
import { TinyColor } from "@ctrl/tinycolor";
import { createPointCB, getClientRect, pointInside } from "dom-plane";
import { onWindowOptimizedResize } from "js/bindGlobalEventListeners";
import * as S3 from "aws-sdk/clients/s3";
import * as MBRPC from "../../../../../../portal_config/mb-json-rpc-client/dist";

tippy.setDefaults({
  animateFill: false,
  arrow: true,
  arrowType: "round",
  placement: "bottom",
  size: "small",
  multiple: true,
  updateDuration: 0,
});

const tippyCb = (element) => {
  if (!(element && element.title.length)) {
    return;
  }
  if (!_.has(element, "_tippy")) {
    fastdom.mutate(() => {
      tippy.one(element, {
        content(reference) {
          const title = reference.getAttribute("title");
          reference.removeAttribute("title");
          return title;
        },
      });
    });
  }
};

Pace.start();
elementReady(".tippy", tippyCb);

onWindowOptimizedResize(() => tippy.hideAllPoppers(), 2000, { leading: true, trailing: false });

Object.assign(MBUI, {
  MBColorPicker,
});
Object.assign(window, {
  tippy,
  autosize,
  Cropper,
  anime,
  sha256,
  tabbable,
  Flow,
  elementReady,
  domPlane: {
    createPointCB,
    getClientRect,
    pointInside,
  },
  S3,
  rpcClient: new MBRPC.MbJsonRpcClient(window.location.href.split("/gwtmain/")[0]),
  MBRPC,
});

export const getThemeCss = (theThemeColor) => {
  const aThemeColor = new TinyColor(theThemeColor);
  const aThemeColorToHexString = aThemeColor.toHexString();
  const aThemeColorIsDark = aThemeColor.isDark();
  const aColor = aThemeColorIsDark ? "#fff" : "#212529";
  const aThemeHoverColor = aThemeColor.clone().darken(15).toHexString();
  const aHoverBackground = aThemeColor.clone().darken(7.5).toHexString();
  const aHoverBorder = aThemeColor.clone().darken(10).toHexString();
  const aActiveBackground = aHoverBorder;
  const aActiveBorder = aThemeColor.clone().darken(12.5).toHexString();
  return `.btn-primary {\
 color: ${aColor};\
 background-color: ${aThemeColorToHexString};\
 border-color: ${aThemeColorToHexString}\
 }\
 .btn-primary:hover {\
 color: ${aColor};\
 background-color: ${aHoverBackground};\
 border-color: ${aHoverBorder}\
 }\
 .btn-primary.disabled,\
 .btn-primary:disabled {\
 color: ${aColor};\
 background-color: ${aThemeColorToHexString};\
 border-color: ${aThemeColorToHexString}\
 }\
 .btn-primary:not(:disabled):not(.disabled).active,\
 .btn-primary:not(:disabled):not(.disabled):active,\
 .show > .btn-primary.dropdown-toggle {\
 color: ${aColor};\
 background-color: ${aActiveBackground};\
 border-color: ${aActiveBorder}\
 }\
 a,\
 .btn-link,\
 .btn-flat-primary {\
 color: ${aThemeColorToHexString};\
 }\
 a:hover,\
 .btn-link:hover,\
 .btn-flat-primary:hover,\
 .btn-flat-primary:focus {\
 color: ${aThemeHoverColor};\
 }\
 .btn-flat-primary.disabled,\
 .btn-flat-primary:disabled {\
 color: ${aThemeHoverColor};\
 }\
 .btn-flat-primary:not(:disabled):not(.disabled).active,\
 .btn-flat-primary:not(:disabled):not(.disabled):active,\
 .show > .btn-flat-primary.dropdown-toggle {\
 color: ${aActiveBackground};\
 }\
 .nav-pills .nav-link.active,.nav-pills .show>.nav-link {\
 color: ${aColor};\
 background-color: ${aThemeColorToHexString};\
 }\
 .dropdown-item.active-primary,\
 .dropdown-item.active-primary:active,\
 .badge-primary {\
 color: ${aColor};\
 background-color: ${aThemeColorToHexString};\
 }\
 .badge-primary[href]:focus,.badge-primary[href]:hover {\
 color: ${aColor};\
 background-color: ${aHoverBackground};\
 }\
 .progress-bar {\
 background-color: ${aThemeColorToHexString};\
 }\
 .x-date-active:not(.x-date-disabled).x-date-selected .btn-date, .x-date-mp-sel .btn-date,\
 .nav-tabs-advanced-search.nav-tabs .nav-link.active,\
 .list-group-item.active {\
 color: ${aColor};\
 background-color: ${aThemeColorToHexString};\
 border-color: ${aThemeColorToHexString}\
 }\
 .form-control:focus,\
 .input-group-trigger-field.x-trigger-wrap-focus {\
 border-color: ${aThemeColorToHexString}\
 }\
 .drop-zone-valid, .DropZone-dragging {\
 box-shadow: inset 0 0 0 1px ${aThemeColorToHexString};\
 }\
 .bg-primary {\
 color: ${aColor} !important;\
 background-color: ${aThemeColorToHexString} !important;\
 }\
 .border-primary {\
 border-color: ${aThemeColorToHexString} !important;\
 }\
 .mb-checkbox.active::before,\
 .x-menu-item-checked .mb-checkbox::before,\
 .gwt-TreeItem-selected .folder-icon,\
 .gwt-TreeItem-selected .workflow-icon,\
 .text-primary {\
 color: ${aThemeColorToHexString} !important\
 }\
 .is-open > .card-header > div > .switch, .active .switch {\
 background-color: rgba(0,0,0, 0.15);\
 }\
 .is-open > .card-header > div > .switch::after, .active .switch::after,\
 .infinite-scroll-item.active {\
 background-color: ${aThemeColorToHexString};\
 }\
 .mat-radio input:checked + span::before {\
 border-color: ${aThemeColorToHexString};\
 }\
 .mat-radio input:checked + span::after {\
 border-color: ${aThemeColorToHexString};\
 background-color: ${aThemeColorToHexString}\
 }`;
};
Object.assign(MBUI.util, {
  getThemeCss,
});
